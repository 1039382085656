import service from "@/utils/request";


//分页查询所有
export const getPayInterfaceInfoData = (params) => service({
    url: '/payInterfaceDefine/queryPage',
    method: "get",
    params: params
});

//添加
export const addPayInterfaceInfo = (data) => service({
    url: '/payInterfaceDefine/add',
    method: "post",
    data: data,
});

//更新
export const updatePayInterfaceInfo = (data) => service({
    url: '/payInterfaceDefine/edit',
    method: "post",
    data: data,
});

//删除（一个/多个）
export const deletesPayInterfaceInfo = (data) => service({
    url: '/payInterfaceDefine/delete/ids',
    method: "post",
    data: data,
});
