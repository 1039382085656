import service from "@/utils/request";


//分页查询所有
export const getPayWayInfoData = (params) => service({
    url: '/payWay/queryPage',
    method: "get",
    params: params
});

//添加
export const addPayWayInfo = (data) => service({
    url: '/payWay/add',
    method: "post",
    data: data,
});

//更新
export const updatePayWayInfo = (data) => service({
    url: '/payWay/updateById',
    method: "post",
    data: data,
});

//删除（一个/多个）
export const deletesPayWayInfo = (data) => service({
    url: '/payWay/deleteByIds',
    method: "post",
    data: data,
});

//查询所有支付方式
export const getPayWayCodes = () => service({
    url: '/payWay/list',
    method: "get",
});
