<template>
    <div>
        <div class="box">
            <a-card hoverable class="add-box" @click="add">
                <div class="add-top">
                    <img class="add-img"
                        src="//jeequan.oss-cn-beijing.aliyuncs.com/jeepay/cdn/d/manager/assets/add-icon.a2f73e90.svg"
                        alt="add-icon">
                    <img class="add-img-hover"
                        src="//jeequan.oss-cn-beijing.aliyuncs.com/jeepay/cdn/d/manager/assets/add-icon-hover.50df40dd.svg"
                        alt="add-icon">
                </div>
                <div class="add-text">新建支付接口</div>
            </a-card>
            <a-card hoverable class="card-box" v-for="item in tableData" :key="item.appId">
                <img slot="cover" alt="example" class="img"
                    src="https://picnew13.photophoto.cn/20181211/baiseqingxinshangwubeijingsheji-31150691_1.jpg" />
                <div class="title-info">{{ item.ifName }}</div>
                <div class="card-footer">
                    <a-tooltip placement="top">
                        <template slot="title">
                            <span>编辑</span>
                        </template>
                        <span @click="editRow(item)"><a-icon type="edit" /></span>
                    </a-tooltip>
                    <a-tooltip placement="top">
                        <template slot="title">
                            <span>删除</span>
                        </template>
                        <span @click="deleteRow(item.ifCode)"><a-icon type="delete" /></span>
                    </a-tooltip>
                </div>
            </a-card>
        </div>

        <!-- 分页器 -->
        <Pagination :total="total" :page="pageNumber" :size="pageSize" @getPage="getPage($event)"
            @getSize="getSize($event)">
        </Pagination>

        <!-- 添加 -->
        <el-drawer title="添加" :wrapperClosable="false" :visible.sync="addFormVisible" direction="rtl"
            :before-close="handleClose" size="35%">
            <div class="drawer-content">
                <el-form :model="addForm" :rules="rules" ref="addForm" label-width="140px" class="demo-ruleForm">
                    <el-form-item label="接口代码" prop="ifCode">
                        <el-input v-model="addForm.ifCode" placeholder="请输入接口代码"></el-input>
                    </el-form-item>
                    <el-form-item label="接口名称" prop="ifName">
                        <el-input v-model="addForm.ifName" placeholder="请输入接口名称"></el-input>
                    </el-form-item>
                    <el-form-item label="接口配置定义描述" prop="mchParams">
                        <el-input v-model="addForm.mchParams" placeholder="请输入接口配置定义描述" type="textarea"></el-input>
                    </el-form-item>
                    <el-form-item label="例：">
                        <el-input disabled
                            value='[{"star":"","values":"","name":"appId","verify":"required","titles":"","type":"input","desc":"商户APPID"},{"star":"","values":"","name":"appPublicKey","verify":"required","titles":"","type":"input","desc":"商户APP公钥"}]'
                            type="textarea"></el-input>
                    </el-form-item>
                    <el-form-item label="页面展示图标" prop="icon">
                        <el-input v-model="addForm.icon" placeholder="请输入页面展示图标"></el-input>
                    </el-form-item>
                    <el-form-item label="页面展示背景色" prop="bgColor">
                        <el-input v-model="addForm.bgColor" placeholder="请输入页面展示背景色"></el-input>
                    </el-form-item>
                    <el-form-item label="状态" prop="status">
                        <el-radio-group v-model="addForm.status">
                            <el-radio :label="1">启用</el-radio>
                            <el-radio :label="0">停用</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="支持的支付方式" prop="wayCodes">
                        <el-checkbox-group v-model="addForm.wayCodes">
                            <el-checkbox :label="item.wayCode" v-for="item in wayCodesList" :key="item.wayCode">{{
                                item.wayName
                            }}</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="备注" prop="remark">
                        <el-input v-model="addForm.remark" placeholder="备注"></el-input>
                    </el-form-item>
                </el-form>
                <div class="drawer-footer">
                    <a-button class="marginRight" @click="addFormCancel">
                        取消
                    </a-button>
                    <a-button class="marginRight" @click="resetForm">
                        重置
                    </a-button>
                    <a-button type="primary" @click="addSubmit">
                        确定
                    </a-button>
                </div>
            </div>
        </el-drawer>

        <!-- 修改 -->
        <el-drawer title="修改" :wrapperClosable="false" :visible.sync="updateFormVisible" direction="rtl"
            :before-close="handleClose" size="35%">
            <div class="drawer-content">
                <el-form :model="updateForm" :rules="rules" ref="updateForm" label-width="140px" class="demo-ruleForm">
                    <el-form-item label="接口代码" prop="ifCode">
                        <el-input v-model="updateForm.ifCode" placeholder="请输入接口代码"></el-input>
                    </el-form-item>
                    <el-form-item label="接口名称" prop="ifName">
                        <el-input v-model="updateForm.ifName" placeholder="请输入接口名称"></el-input>
                    </el-form-item>
                    <el-form-item label="接口配置定义描述" prop="mchParams">
                        <el-input v-model="updateForm.mchParams" placeholder="请输入接口配置定义描述" type="textarea"></el-input>
                    </el-form-item>
                    <el-form-item label="例：">
                        <el-input disabled
                            value='[{"star":"","values":"","name":"appId","verify":"required","titles":"","type":"input","desc":"商户APPID"},{"star":"","values":"","name":"appPublicKey","verify":"required","titles":"","type":"input","desc":"商户APP公钥"}]'
                            type="textarea"></el-input>
                    </el-form-item>
                    <el-form-item label="页面展示图标" prop="icon">
                        <el-input v-model="updateForm.icon" placeholder="请输入页面展示图标"></el-input>
                    </el-form-item>
                    <el-form-item label="页面展示背景色" prop="bgColor">
                        <el-input v-model="updateForm.bgColor" placeholder="请输入页面展示背景色"></el-input>
                    </el-form-item>
                    <el-form-item label="状态" prop="status">
                        <el-radio-group v-model="updateForm.status">
                            <el-radio :label="1">启用</el-radio>
                            <el-radio :label="0">停用</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="支持的支付方式" prop="wayCodes">
                        <el-checkbox-group v-model="updateForm.wayCodes">
                            <el-checkbox :label="item.wayCode" v-for="item in wayCodesList" :key="item.wayCode">{{
                                item.wayName
                            }}</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="备注" prop="remark">
                        <el-input v-model="updateForm.remark" placeholder="备注"></el-input>
                    </el-form-item>
                </el-form>
                <div class="drawer-footer">
                    <a-button class="marginRight" @click="updateFormCancel">
                        取消
                    </a-button>
                    <a-button class="marginRight" @click="resetForm">
                        重置
                    </a-button>
                    <a-button type="primary" @click="updateSubmit">
                        确定
                    </a-button>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import {
    getPayInterfaceInfoData,
    deletesPayInterfaceInfo,
    updatePayInterfaceInfo,
    addPayInterfaceInfo,
} from "@/api/payManagement/payInterfaceInfo";
import { getPayWayCodes } from "@/api/payManagement/payWayInfo";
import Pagination from "@/components/Pagination";

export default {
    name: 'payInterfaceInfo',
    components: { Pagination },
    data() {
        return {
            //渲染列表数据
            tableData: [],
            //总条数
            total: 0,
            //当前页数
            pageNumber: 1,
            //当前每页条数
            pageSize: 5,
            //添加抽屉是否展示
            addFormVisible: false,
            //添加表单数据
            addForm: {
                status: 1,
                wayCodes: []
            },
            //修改抽屉是否展示
            updateFormVisible: false,
            //修改表单数据
            updateForm: {},
            //添加表单数据规则
            rules: {
                ifCode: [
                    { required: true, message: '请输入接口代码', trigger: 'blur' }
                ],
                ifName: [
                    { required: true, message: '请输入接口名称', trigger: 'blur' }
                ],
                mchParams: [
                    { required: true, message: '请输入接口配置定义描述', trigger: 'blur' }
                ],
                icon: [
                    { required: true, message: '请输入页面展示图标', trigger: 'blur' }
                ],
                bgColor: [
                    { required: true, message: '请输入页面背景色', trigger: 'blur' }
                ],
                status: [
                    { required: true, message: '请选择状态', trigger: 'blur' }
                ],
                wayCodes: [
                    { required: true, message: '请选择支持的支付方式', trigger: 'blur' }
                ],
                remark: [
                    { required: true, message: '请输入备注', trigger: 'blur' }
                ],
            },
            //支付方式列表数据
            wayCodesList: []

        };
    },
    mounted() {
        this.getTableData()
        this.getPayWayCodes()
    },

    methods: {
        //分页查询
        async getTableData() {
            const data = { pageNum: this.pageNumber, pageSize: this.pageSize };
            const res = await getPayInterfaceInfoData(data);
            console.log(res)
            this.tableData = res.data.rows
            this.total = parseInt(res.data.total)
        },

        //获取所有支付方式
        async getPayWayCodes() {
            const res = await getPayWayCodes()
            let arr = res.data.map(item => {
                return { 'wayCode': item.wayCode, 'wayName': item.wayName }
            })
            this.wayCodesList = arr
        },

        //添加按钮
        add() {
            this.addForm = {
                status: 1,
                wayCodes: []
            }
            this.addFormVisible = true
        },

        //添加抽屉提交按钮
        async addSubmit() {
            const res = await addPayInterfaceInfo(this.addForm)
            this.$message.success("添加成功");
            this.getTableData()
            this.addForm = {
                status: 1,
                wayCodes: []
            }
            this.addFormVisible = false
        },

        //添加抽屉取消按钮
        addFormCancel() {
            this.addFormVisible = false
        },

        //修改
        editRow(row) {
            this.updateForm = JSON.parse(JSON.stringify(row))
            this.updateForm.mchParams = JSON.stringify(this.updateForm.mchParams)
            this.updateFormVisible = true
        },

        //修改抽屉保存按钮
        async updateSubmit() {
            this.updateForm.mchParams = JSON.parse(this.updateForm.mchParams)
            const res = await updatePayInterfaceInfo(this.updateForm)
            this.getTableData()
            this.$message.success("修改成功");
            this.updateForm = {
                status: 1,
                wayCodes: []
            }
            this.updateFormVisible = false
        },

        //修改抽屉取消按钮
        updateFormCancel() {
            this.updateFormVisible = false
            this.updateForm = {
                status: 1,
                wayCodes: []
            }
        },

        // 删除
        deleteRow(id) {
            this.$confirm('此操作将永久删除该商户, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const data = [id]
                const res = await deletesPayInterfaceInfo(data);
                this.getTableData();
                this.$message.success("删除成功");
            }).catch((e) => {
                if (e == 'cancel') {
                    this.$message.info("已取消删除");
                }
            });
        },


        //表单重置（添加/修改）
        resetForm() {
            this.addForm = {}
            this.updateForm = {}
        },

        //抽屉关闭回调
        handleClose(done) {
            this.addForm = {
                status: 1,
                wayCodes: []
            }
            this.updateForm = {
                status: 1,
                wayCodes: []
            }
            done()
        },

        //获取当前页数
        getPage(value) {
            this.pageNumber = value;
            this.getTableData();
        },

        //获取每页多少条
        getSize(value) {
            this.pageSize = value;
            this.getTableData();
        },
    },
}
</script>

<style scoped lang="less">
/* 抽屉内容 */
.drawer-content {
    padding: 0 30px 70px 20px;

    .drawer-footer {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        border-top: 1px solid #e9e9e9;
        padding: 10px 16px;
        background: #fff;
        text-align: right;
        z-index: 1;
    }

    .marginRight {
        margin-right: 8px;
    }

    // 表单文本域高度

    :deep .el-textarea__inner {
        height: 160px;
    }
}

// 卡片盒子
.box {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    grid-gap: 20px;

    :deep .ant-card-body {
        padding: 0;
    }

    .card-box {
        width: 330px;
        border-radius: 6px;
        box-sizing: border-box;
        // border: none;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);

        .img {
            width: 100%;
            height: 110px;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
        }

        .title-info {
            text-align: center;
            font-size: 15px;
            font-weight: 600;
            color: black;
            margin: 17px;
        }

        .card-footer {
            border-top: 0.5px solid #f4f5f7;
            padding: 16px;
            color: #5c5c5c;
            font-size: 16px;
            display: flex;
            justify-content: space-around;

            span:hover {
                color: #95c0fc;
            }
        }
    }

    .add-box {
        width: 330px;
        height: 225.8px;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        border: 2px dashed rgba(0, 0, 0, .15);
        background: rgba(0, 0, 0, .03);
        border-radius: 6px;
        box-sizing: border-box;
        cursor: pointer;

        .add-top {
            width: 80px;
            height: 80px;
            opacity: 1;

            .add-img-hover {
                opacity: 0;
            }
        }

        .add-text {
            padding-top: 5px;
            font-size: 13px;
            color: rgba(0, 0, 0, .35);
        }

        &:hover {
            transition: all 0.6s;
            border: 2px dashed #a7bbf8;
            background: #e3e9f6;
        }

        &:hover .add-img-hover {
            transition: all 0.6s;
            opacity: 1;
        }

        &:hover .add-img {
            transition: all 0.6s;
            display: none;
        }

        &:hover .add-text {
            transition: all 0.6s;
            color: #1953ff;
        }
    }
}
</style>